import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import PrivateRouter from './Private';


const Register = Loadable(
  lazy(() => import('../views/authentication/Register2'))
);


/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
);
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/login4')));
const Recovery = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

const Close = Loadable(lazy(() => import('../views/authentication/close')));

/* ****Pages***** */
const Dashboard = Loadable(
  lazy(() => import('../views/dashboards/Dashboard1'))
);

const Calendario = Loadable(
  lazy(() => import('../views/autoexpertos/MyCalendar/ACalendar'))
);

const Scheduled = Loadable(lazy(() => import('../views/dashboards/Scheduled')));
const InWorkshop = Loadable(
  lazy(() => import('../views/dashboards/InWorkshop'))
);

const Additional = Loadable(
  lazy(() => import('../views/dashboards/Additionnal'))
);


const Withdraw = Loadable(lazy(() => import('../views/dashboards/Withdraw')));
const Finalized = Loadable(lazy(() => import('../views/dashboards/Finalized')));
const Moving = Loadable(lazy(() => import('../views/dashboards/Moving')));
const Reporting = Loadable(lazy(() => import('../views/dashboards/Reporting')));
const Metabase = Loadable(lazy(() => import('../views/dashboards/Metabase')));
const BookingView = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingView'))
);


const MisVehiculos = Loadable(lazy(() => import('../views/autoexpertos/profile/vehicules')));

const ServiceListAll = Loadable(lazy(() => import('../views/autoexpertos/Services/all')));

const ServiceListPENDING = Loadable(lazy(() => import('../views/autoexpertos/Services/index')));
const ServiceListMOVING = Loadable(lazy(() => import('../views/autoexpertos/Services/index')));
const ServiceListINPROGRESS = Loadable(lazy(() => import('../views/autoexpertos/Services/index')));
const ServiceListREADY = Loadable(lazy(() => import('../views/autoexpertos/Services/index')));
const ServiceListFINISHED = Loadable(lazy(() => import('../views/autoexpertos/Services/index')));


const NewCar = Loadable(
  lazy(() => import('../views/autoexpertos/profile/addCar'))
);


const ViewCar = Loadable(
  lazy(() => import('../views/autoexpertos/profile/viewCar'))
);


const ViewService = Loadable(
  lazy(() => import('../views/autoexpertos/profile/viewService'))
);


/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      {
        path: '/home',
        exact: true,
        element: <PrivateRouter component={<Dashboard />} />,
      },

      {
        path: '/calendar',
        exact: true,
        element: (
          <PrivateRouter component={<Calendario />} />
        ),
      },

      {
        path: '/vehiculos',
        exact: true,
        element: (
          <PrivateRouter component={<MisVehiculos />} />
        ),
      },


      {
        path: '/services',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListAll/>} />
        ),
      },


      {
        path: '/services/agendados',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListPENDING status={'PENDING'} name={"Agendado"} />} />
        ),
      },

      {
        path: '/services/traslado',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListMOVING status={'MOVING'} name={"Translado"} />} />
        ),
      },

      {
        path: '/services/taller',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListINPROGRESS status={'INPROGRESS'} name={"En Taller"} />} />
        ),
      },

      {
        path: '/services/retirar',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListREADY status={'READY'} name={"Listo"} />} />
        ),
      },

      {
        path: '/services/finalizado',
        exact: true,
        element: (
          <PrivateRouter component={<ServiceListFINISHED status={'FINISHED'} name={"Finalizado"} />} />
        ),
      },


      {
        path: '/newcar',
        exact: true,
        element: (
          <PrivateRouter component={<NewCar />} />
        ),
      },

      {
        path: '/car/:idCar',
        exact: true,
        element: (
          <PrivateRouter component={<ViewCar />} />
        ),
      },

      {
        path: '/detail/servicio/:idService',
        exact: true,
        element: (
          <PrivateRouter component={<ViewService />} />
        ),
      },




    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'close', element: <Close /> },
      { path: 'register', element: <Register /> },
      { path: 'recovery/:token', element: <Recovery /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      
    ],
  },
];

export default Router;
