import { ReactComponent as LogoDark } from './assets/autoexpertos/logo.svg';
import { ReactComponent as LogoLight } from './assets/autoexpertos/logo.svg';

let Menuitems = [
  {
    title: 'Inicio',
    icon: 'home',
    href: '/home',
  },
  {
    title: 'Calendario',
    icon: 'calendar',
    href: '/calendar',
  },
  {
    title: 'Servicios',
    icon: 'truck',
    href: '/services',
    collapse: true,
    children: [
      {
        title: 'Todos',
        icon: 'corner-down-right',
        href: '/services',
      },


      {
        title: 'Agendado',
        icon: 'corner-down-right',
        href: '/services/agendados',
      },
      {
        title: 'En Traslado',
        icon: 'corner-down-right',
        href: '/services/traslado',
      },
      {
        title: 'En Taller',
        icon: 'corner-down-right',
        href: '/services/taller',
      },

      {
        title: 'Listo Retirar',
        icon: 'corner-down-right',
        href: '/services/retirar',
      },
      {
        title: 'Finalizado',
        icon: 'corner-down-right',
        href: '/services/finalizado',
      },
    ],
  },

 
  {
    title: 'Vehículos',
    icon: 'archive',
    href: '/vehiculos',
  },

  

  {
    title: 'Cerrar Sesión',
    icon: 'log-out',
    href: '/auth/close',
  },
];



const nameApp = 'Empresas - AutoExpertos';
const urlEndPoint = process.env.REACT_APP_API;
const forceLogin = true;

const DateRevision = [
  { month: 1, digite: 9 },
  { month: 2, digite: 0 },
  { month: 3, digite: null },
  { month: 4, digite: 1 },
  { month: 5, digite: 2 },
  { month: 6, digite: 3 },
  { month: 7, digite: 4 },
  { month: 8, digite: 5 },
  { month: 9, digite: 6 },
  { month: 10, digite: 7 },
  { month: 11, digite: 8 },
  { month: 12, digite: null },
];



export { LogoLight, LogoDark, Menuitems, nameApp, urlEndPoint, DateRevision };
